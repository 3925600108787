import React from 'react'
import EventOverviewCard from './EventOverviewCard'
import { CardDeck } from 'react-bootstrap'

function KonzerteNebentbuehne({ events }) {
    return (
        <CardDeck>
            {events.sort((a, b) => {
                // Convert the timestamps dates from strings to date objects.
                let da = new Date(a.node.body[0].primary.date_time),
                    db = new Date(b.node.body[0].primary.date_time);
                return da - db;
            }).map((event, i) => {
                if (event.node.body[0].primary.event_type === "Konzert" && event.node.body[0].primary.event_place === "Nebenbühne") {
                    return (
                        <EventOverviewCard event={event} key={i} />
                    )
                } else { return null; }
            })}
        </CardDeck>
    )
}

export default KonzerteNebentbuehne
